.Footer {
  /* style */
  background-color: white;
  border-top: 1px solid rgba(208, 208, 208, 0.3);

  position: absolute;
  bottom: 0;
  height: 4vh;
  width: 100vw;

  /* align footer text on the y axis */
  display: flex;
  align-items: center;
}

.FooterText {
  /* style */
  color: black;
  font-family: "SFPro";
  font-weight: lighter;
  font-size: 10px;
}

@media (max-width: 1024px) {
  .Footer {
    justify-content: center;
  }
}

/* on non mobile move the footer text to the right a bit */
@media (min-width: 1025px) {
  .FooterText {
    position: inherit;
    left: 30px;
  }
}
