/* will need to block token animation when drop down menu is down, since the position needs to be absolute to overlap content on home */

/* mobile */
@media (max-width: 1000px) {
  .TokenLogo {
    height: 25px;
    width: 25px;
  }

  .TokenLogoContainer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;
  }

  .TokenAnimation {
    position: absolute;
    top: 35%;
    height: 50vh;
    width: 100vw;
  }

  /* add opacity fade */
  @keyframes FadeToken {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }
}
