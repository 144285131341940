body {
  margin: 0;

  overflow: hidden;
  background-color: white;
}

/* fonts */
@font-face {
  font-family: "SFPro";
  src: url("./fonts/SF-Pro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
