/* disable x and y scroll */
.HomePage {
  background-color: white;
  overflow-x: hidden;
  overflow-y: hidden;

  font-family: "SFPro";
  color: black;

  margin: 0;

  height: 100vh;
  width: 100vw;

  display: grid;
  justify-items: center;
}

.LogoAndHeader {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 30px;

  padding-top: 20vh;
  padding-left: 10px;
  padding-right: 10px;
}

.TextLogo {
  width: 50vw;
}

.HomePageSubHeader {
  line-height: 120%;
  font-weight: 500;

  text-align: center;

  font-size: 32px;
}

@media (max-width: 1000px) {
  .TextLogo {
    width: 70vw;
  }
}

@media (max-width: 768px) {
  .HomePageSubHeader {
    font-size: 20px;

    line-height: 150%;
    font-weight: 500;
  }

  .LogoAndHeader {
    gap: 20px;
  }
}

@media (max-width: 500px) {
  .HomePageSubHeader {
    font-size: 18px;

    line-height: 150%;
    font-weight: 500;
  }

  .LogoAndHeader {
    gap: 20px;
  }
}
