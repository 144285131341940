.DropDownMenu {
  background-color: white;
  position: absolute;
  right: 0;
  top: 0vh;
  width: 40vw;
  height: 96vh;
}

.DropDownMenuItems {
  display: grid;
  align-items: center;

  padding: 9vh 0px 0px 20px;
  gap: 20px;
}

.DropDownButton {
  width: 200px;

  background-color: white;
  color: black;
  border: none;
  padding: 0 0 0 0;

  font-family: "SFPro";
  font-size: 24px;
  font-weight: 550;
  letter-spacing: 0.005em;

  display: flex;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
}

.BlurHalfPage {
  border: none;
  position: absolute;
  top: 0;
  left: 0;

  height: 96vh;
  width: 60vw;
  background-color: rgba(0, 0, 0, 0.3);

  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);

  cursor: pointer;
}

@media (max-width: 768px) {
  .DropDownMenu {
    /* position so footer is displayed */
    position: absolute;
    right: 0;
    top: 8vh;
    width: 100vw;
    height: 88vh;
  }

  .BlurHalfPage {
    position: absolute;
    border: none;

    top: 0;

    height: 8vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.3);

    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);

    cursor: pointer;
  }
}
