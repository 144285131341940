.HeaderContent {
  width: 100vw;
  height: 8vh;

  background-color: white;

  font-family: "SFPro";
  font-size: 16px;

  /* center items */
  display: flex;
  align-items: center;

  padding: 0 0 0 2.5vh;
}

.HeaderLogo {
  display: flex;
  align-items: center;
  height: 24px;
}

.TradeButton {
  /* button style */
  width: 75px;
  height: auto;
  border-radius: 4px;

  background-color: black;
  color: white;

  border: none;
  cursor: pointer;

  /* text style */
  font-size: 16px;
  padding: 4px 0 4px 0;

  /* center button text */
  display: flex;
  justify-content: center;
  align-items: center;
}

.MoreMenuButton {
  /* remove all button styling transparent background */
  border: none;
  background-color: transparent;
  padding: 0 0 0 0;

  /* add pointer */
  cursor: pointer;

  /* center items */
  display: flex;
  justify-content: center;
  align-items: center;
}

.MoreMenuButton img {
  height: 15px;
}

.TradeAndMoreMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.5vh;

  position: absolute;
  right: 2.5vh;
}

/* make header height larger on smaller viewports */
@media (max-width: 500px) {
  .HeaderContent {
    padding: 0 0 0 5vw;
  }
  .TradeAndMoreMenu {
    gap: 5vw;
    position: absolute;
    right: 5vw;
  }

  .TradeButton {
    font-weight: 500;
  }
}
