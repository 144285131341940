/* drop down animations for sign up and drop down menu */

/* small viewport */
@keyframes SlideInBlurMobile {
  from {
    transform: translatey(-8vh);
  }
  to {
    transform: translatey(0vh);
  }
}

@keyframes SlideOutBlurMobile {
  from {
    transform: translatey(0vh);
  }
  to {
    transform: translatey(-8vh);
  }
}

@keyframes SlideInMenuMobile {
  from {
    transform: translatey(88vh);
  }

  to {
    transform: translatey(0);
  }
}

@keyframes SlideOutMenuMobile {
  from {
    transform: translatey(0);
  }

  to {
    transform: translatey(88vh);
  }
}

/* fade opacity on menu items */
@keyframes FadeInMenuItems {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes FadeOutMenuItems {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* regular viewport */

@keyframes SlideInBlurRegular {
  from {
    transform: translatex(-60vw);
  }
  to {
    transform: translatex(0vw);
  }
}

@keyframes SlideOutBlurRegular {
  from {
    transform: translatex(0vw);
  }
  to {
    transform: translatex(-60vw);
  }
}

@keyframes SlideInMenuRegular {
  from {
    transform: translatex(40vw);
  }
  to {
    transform: translatex(0vw);
  }
}

@keyframes SlideOutMenuRegular {
  from {
    transform: translatex(0vw);
  }
  to {
    transform: translatex(40vw);
  }
}
