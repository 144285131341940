.Error404Container {
  font-family: "SFPro";
  color: black;

  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.Logo {
  /* logo size */
  height: 48px;
  line-height: 100%;
}

.LogoContainer {
  width: min-content;
}

.ErrorMessage {
  padding-left: 50px;
  padding-top: 50px;
  display: grid;
  gap: 20px;
}

.ErrorHeader {
  font-size: 32px;
  font-weight: 700;
  line-height: 150%;

  margin: 0;
}

.ErrorCode {
  margin: 0;

  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
  line-height: 100%;
}

.RedirectButton {
  /* button size and color */
  width: min-content;

  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  color: #0052ff;
  background-color: white;

  border: none;
  border-radius: 0px;
  cursor: pointer;

  /* move text to far left */
  padding-left: 0;
}

@media (max-width: 500px) {
  .ErrorHeader {
    font-size: 16px;

    line-height: 150%;
  }

  .ErrorCode {
    font-size: 12px;
    font-weight: 700;

    line-height: 150%;
  }

  .Error404Container {
    align-items: center;
  }

  .ErrorMessage {
    gap: 15px;
  }
}
